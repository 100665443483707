@import './styles/_variables';
@import './styles/_mixins';

body {
  @include type-style;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  background-color: $primary-bg;
}

.full-page-loader {
  background-color: red;
  min-height: 100vh;
  min-width: 100vw;
}

input {
  caret-color: dodgerblue;
}

button:disabled,
input:disabled {
  opacity: 0.5 !important;
  font-weight: 300 !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
}
