@import '../../../styles/_variables';
@import '../../../styles/_breakpoints';

.spacer {
  margin: 0 $spacing-x;
  padding: $spacing-y 0;

  &.flex {
    flex: 1;
    display: flex;
  }

  &.full-width {
    width: 100%;
  }

  &.large-x {
    margin-left: $spacing-x-large;
    margin-right: $spacing-x-large;
  }

  &.large-y {
    padding-top: $spacing-y-large;
    padding-bottom: $spacing-y-large;
  }

  &.small-y {
    padding-top: $spacing-y-small;
    padding-bottom: $spacing-y-small;
  }

  &.no-x {
    margin-left: 0;
    margin-right: 0;
  }

  &.no-y {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.no-top {
    padding-top: 0;
  }

  &.no-bottom {
    padding-bottom: 0;
  }

  &.large-left {
    margin-left: $spacing-x-large;
  }

  &.large-right {
    margin-right: $spacing-x-large;
  }

  &.no-right {
    margin-right: 0;
  }

  &.no-left {
    margin-left: 0;
  }

  &.margin-top-auto {
    margin-top: auto;
  }

  &.margin-bottom-auto {
    margin-bottom: auto;
  }
}

@media screen and (max-width: $MEDIUM_MAX) {
  .spacer.responsive {
    margin: 0 $spacing-x-small;
    padding: $spacing-y-small 0;

    &.large-y {
      padding: $spacing-y 0;
    }

    &.large-x {
      margin: 0 $spacing-x;
    }

    &.large-left {
      margin-left: $spacing-x;
    }

    &.large-right {
      margin-right: $spacing-x;
    }

    &.no-y {
      padding: 0;
    }
  }
}

@media screen and (max-width: $SMALL_MAX) {
  .spacer.responsive {
    margin: 0 $spacing-x-small;

    &.large-x {
      margin: 0 $spacing-x;
    }

    &.large-left {
      margin-left: $spacing-x;
    }

    &.large-right {
      margin-right: $spacing-x;
    }
  }
}
