$grey: #303030;
$grey-dark: #212121;
$grey-light: #606060;
$grey-light2: #e5e6ea;
$silver: #c0c0c0;

$blue-dark2: #1b579b;
$blue-dark: #2b79d8;
$blue-dodger: dodgerblue;
$blue-light: #1089ed;
$blue-lighter: #add8e6;

$red: #ff0000;
$red-dark: #ea3a3a;
$red-light: #f27474;

$green-dark: #45e858;
$green-light: #6de879;

$font-dark: #222;
$font-light: #fff;
$font-green: #30c13e;

$orange: #f27836;
$pink: #ff1493;
$purple: #bb86fc;
$teal: #03dac6;

$white: #fff;

// Dark mode color scheme
$error: $red-light;

$primary-font: sans-serif;

//Backgrounds
$primary-bg-dark: $grey-dark;
$primary-bg: $grey-light;
$primary-bg-light: $grey-light;

// States
$hover: $blue-lighter;
$focus: $purple;

$primary: $orange;
$primary-text: $white;

$font-weight-light: 100;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Border
$border-radius: 5px;

// Max Width
$width-main: 1280px;

// Spacing
$spacing-x-small: 9px;
$spacing-x: 18px;
$spacing-x-large: 36px;
$spacing-y-small: 7px;
$spacing-y: 14px;
$spacing-y-large: 28px;
$input-spacing: 6px;
$input-spacing-x: 8px;
$input-spacing-y: 6px;

// Site specific colors
$orange-dk: #f46c22;
$green-dk: #9ac434;
$blue-fd: #1493ff;
$green-fd: #2cb459;
$purple-yahoo: #7b0099;
$purple-dark-yahoo: #400090;
$blue-ft: #3276b1;
