@import './_variables';

@mixin type-style {
  color: $font-dark;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight-regular;

  .light {
    font-weight: $font-weight-light;
  }

  .bold {
    font-weight: $font-weight-bold;
  }
}

@mixin paragraph {
  @include type-style;
  color: $primary-text;
  line-height: 20px;
}

@mixin ul-style {
  margin: 0;
  padding: 0;
}

@mixin input-style {
  @include type-style;
  border: 1px solid $grey-light;
  padding: $input-spacing-y $input-spacing-x;
  box-sizing: border-box;

  &[type='submit'] {
    cursor: pointer;
  }

  &.left-spacing {
    margin-left: $input-spacing-x;
  }

  &.right-spacing {
    margin-right: $input-spacing-x;
  }

  &.bottom-spacing {
    margin-bottom: $input-spacing-y;
  }
}

@mixin button-style {
  @include input-style;
  background-color: white;
  border: 1px solid $grey;
  cursor: pointer;

  &:hover {
    background-color: $hover;
    color: black;
  }

  &.dark {
    border: 1px solid $grey-dark;
    background-color: $primary-bg-dark;
    color: $font-light;
  }

  &.green {
    background-color: lightgreen;
    color: $font-dark;
    outline: none;
    border: none;
    outline-style: none;
    -moz-outline-style: none;

    &:hover,
    :active {
      background-color: green;
    }
  }

  &.invisible {
    background-color: transparent;
    border: none;

    &:hover,
    :active {
      background-color: transparent;
      color: $hover;
      border: none;
      outline-style: none;
      -moz-outline-style: none;
      outline: none;
    }

    &:focus {
      outline: 0;
    }
  }

  &.primary {
    background-color: $primary;
    color: $primary-text;
  }

  &:hover {
    background-color: $hover;
    color: black;
    cursor: pointer;
  }

  &:focus {
    outline: 1px solid dodgerblue;
  }

  a {
    @include input-style;
    border: none;
    font-style: normal;
    text-decoration: none;
    padding: 0;
  }

  &.blue {
    background-color: $blue-dark;
    color: $white;

    &:hover {
      background-color: $blue-light;
    }
  }

  &.blue-dark {
    background-color: $blue-dark;
    color: $white;

    &:hover {
      background-color: $blue-light;
    }
  }

  &.max-height {
    box-sizing: border-box;
    padding: 0;
    min-height: 30px;
    max-height: 30px;
    line-height: 30px;
  }

  &.submit {
    background-color: $blue-dark;
  }

  &.border-bottom-left-radius {
    border-bottom-left-radius: $border-radius;
  }

  &.border-bottom-right-radius {
    border-bottom-right-radius: $border-radius;
  }

  &.border-top-left-radius {
    border-top-left-radius: $border-radius;
  }

  &.border-top-right-radius {
    border-top-right-radius: $border-radius;
  }

  &.border-radius {
    border-radius: $border-radius;
  }

  &.disabled {
    border: none;
    background-color: transparent;
    cursor: default;
    color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &.red-dark {
    background-color: $red-dark;
    color: $white;

    &:hover,
    :active {
      background-color: $white;
      color: red;
    }
  }
}

@mixin h1 {
  font-weight: $font-weight-regular;
  margin: 0;
  font-size: 26px;
}

@mixin h2 {
  font-weight: $font-weight-regular;
  margin: 0;
  font-size: 22px;
}

@mixin h3 {
  font-weight: $font-weight-regular;
  margin: 0;
  font-size: 18px;
}
